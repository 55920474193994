@import 'utils/variables';
@import 'components/type';
@keyframes showUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
/*--------- POP UP --------- */

.popup {
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100svh;
    z-index: 4;
    background: rgba(37, 37, 37, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    .message {
        position: relative;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 22px;
        padding-right: 22px;
        padding-top: 52px;
        padding-bottom: 22px;
        button {
            border: none;
            border-top: #252525 1px solid;
            background-color: #644840;
            color: #F5F5F5;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 5px;
            padding-right: 5px;
            border: #644840 1px solid;
        }
        .close {
            background-color: unset;
            position: absolute;
            top: 22px;
            margin: 0;
            right: 22px;
            width: min-content;
            padding: 0;
            svg {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                fill: #644840;
            }
            
        }
    }
}
.remove {
    display: none;
}
 .scrollblocked {
    overflow: hidden;
 }

/*--------- POP UP --------- */
.none {
    display: none;
}
body {
    margin: 0;
    background-color: #F5F5F5;
}
a, p, h1, h2, h3, button, ul {
    font-family: 'Chau Philomene One', sans-serif;
    font-weight: normal;
}
p, li {
    font-size: 16px;
    line-height: 22px;
}
.center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        text-align: center;
    }
}
.centermobile {
    height: 100vh;
    display: flex;
    align-items: center;
}
header {
    position: fixed;
    top: 0;
    width: 100%;
    transition: 0.5s;
    z-index: 2;
    .header-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
        padding-left:22px;
        padding-right: 22px;
        .languages {
            display: flex;
            justify-content: space-between;
            width: 46px;
        }
        a{
            color: #F5F5F5;
            text-decoration: none;
            overflow: hidden;
        }
        #français:after, #english:after {
            width: 100%;
            height: 1px;
            content: "";
            display: block;
            background-color: #f5f5f5;
            position: relative;
            transform: translateX(-100%)
        }
        #français.active:after, #english.active:after {
            transform: translateX(0)
        }
        background: #252525;
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 57px;
            height: 57px;
            border: 2px solid #F5F5F5;
            transform: rotate(45deg);
            transition: transform 0.5s;
            position: absolute;
            left: 36px;
            top: 19px;
            background: #252525;
            outline: #252525 1px solid;
            span{
                font-size: 20px;
                transform: rotate(-45deg);
            }
        }
        .logo:hover {
            transform: rotate(0);
        }
    }

}
.website_container {
    margin-top: 183px;
    margin-bottom: 50vh;
    padding-left: 22px;
    padding-right: 22px;
    :last-child {
        margin-bottom: 0;
    }
    &__slider {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 22px;
        row-gap: 0;
        &__website, &__title {
            grid-column: 1/-1;
            img {
                width: calc(100% - 2px);
                border: #252525 1px solid;
                display: block;
            }
        }
        &__website {
            a {
                text-decoration: none;
            }
            h2 {
                color: #F5F5F5;
                background-color: #252525;
                text-align: center;
                margin: 0;
            }
            margin-bottom: 88px;
        }
        &__title {
            height: 505px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            h1 {
                margin-top: 0;
                font-size: 40px;
                margin-bottom: 14px;
                width: 100%;
                display: flex;
                align-items: center;
            }
            h1::before, h1::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: #252525;
            }
            h1::before {
                margin-right: 14px;
            }
            h1::after {
                margin-left: 14px;
            }
            &__presentation {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }
    
}
.phone_card {
    position: fixed;
    bottom: 138px;
    transition: 0.5s;
    width: 100%;
    &__header {
        color: #F5F5F5;
        background-color: #252525;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 22px;
        padding-right: 22px;
        h2, h3 {
            font-size: 16px;
        }
        &__right {
            display: flex;
            &__level {
                margin-right: 14px;
                display: flex;
                flex-direction: column;
                align-items: center;
                h3 {
                    margin: 0;
                }
                .progression-bar {
                    width: 84px;
                    background-color: #644840;
                    height: 7px;
                    display: flex;
                    overflow: hidden;
                    div {
                        width: 50%;
                        height: 100%;
                        background-color: #F5F5F5;
                        transform-origin: left;
                        
                    }
                }
            }
            button {
                background: none;
                border: none;
                svg {
                    fill: #F5F5F5;
                    width: 24px;
                    display: flex;
                    align-items: center;    
                }
            }
        }
    }

    @keyframes blink {
        0% {
            opacity: 0;
        }
        97% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes disapear {
        0% {
            opacity: 1;
        }
        97% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes talking1 {
        33.33% {opacity: 1;}
        100% {opacity:0}
    }
    @keyframes talking2 {
        33.33% {opacity: 0;}
        66.66% {opacity: 1;}
        100% {opacity:0}
    }
    @keyframes talking3 {
        66.66% {opacity: 0;}
        99.99% {opacity: 1;}
        100% {opacity:0}
    }

    &__main-content {
        display: flex;
        &__image {
            min-width: 110px;
            background-color: #FFF4E3;
            border-right: 1px #252525 solid;
            position: relative;
            picture {
                position: absolute;
                top: 0;
                left: 0;

                height: 100%;
                display: flex;
                align-items: flex-end;
                img {
                    display: block;
                    max-width: 110px;
                    max-height: 110px;
                }
            }
            .notalking {
                animation: showUp 1s;
                animation: disapear 5.5s step-start infinite;
            }
            .blinking {
                opacity: 0;
                animation: blink 5.5s step-start infinite;
            }
            .talking {
                animation: talking1 0.35s step-start infinite;
            }
            .speaking-1 {
                opacity: 0;
                animation: talking2 0.35s step-start infinite;
            }
            .speaking-2 {
                opacity: 0;
                animation: talking3 0.35s step-start infinite;
            }
            .unactive {
                visibility: hidden;
                animation: none;
            }
        }
        &__text {
            padding: 10px 23px;
            background-color: #F5F5F5;
            width: 100%;
            min-height: 90px;
            p {
                margin: 0;
            }
        }
    }
}
.skill_sheet {
    position: fixed;
    bottom: 96px;
    width: 100%;
    z-index: 1;
    transition: 0.5s;
    &__button {
        border: none;
        border-top: #252525 1px solid;
        background-color: #644840;
        color: #F5F5F5;
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
    }
    &__list {
        background-color: #252525;
        max-height: 40vh;
        overflow: scroll;
        :last-child {
            margin-bottom: 0;
        }
        ul {
            overflow-y: scroll;
            margin: 0;
            list-style: none;
            color: #F5F5F5;
            padding: 44px 22px;
            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 22px;
                span {
                    max-width: 48%;
                }
                :last-child {
                    text-align: end;
                }
            }

        }
    }
}

footer {
    z-index: 2;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #252525;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 96px;
    align-items: center;
    a {
        text-decoration: none;
        color: #F5F5F5;
        fill: #F5F5F5;
    }
    .instagram {
        height: 24px;
        span {
            width: 0;
            height: 0;
            display: block;
            overflow: hidden;
        }
        svg {
            width: 24px;
            height: 24px;
        }
    }
}

@media(min-width: 1000px) {
/*--------- POP UP --------- */
    .popup {
        .message {
            &__avertissement {
                display: flex;
                .right-message {
                    padding-left: 10px;
                    margin-left: 10px;
                    border-left: #252525 1px dashed;
                }
            }
            button {
                cursor: pointer;
                margin-top: 28px;
                font-size: 20px;
                padding-left: 16px;
                padding-right: 16px;
            }
            button:hover {
                background-color: #F5F5F5;
                color: #644840;
            }
        }
    }

/*--------- POP UP --------- */
    p, li {
        font-size: 18px;
        line-height: 25px;
    }
    header {
        .header-container {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            padding-left:0;
            padding-right:0;
            gap: 28px;
            #français:after, #english:after {
                transition: transform 0.5s;
            }
            #français:hover:after, #english:hover:after {
                transform: translateX(0)
            }
          /*  padding-right: calc(((100vh - 224px)/9)*2); */
            .logo {
                left: calc(((100vh - 224px)/9)*2 + 28px);
            }
            .languages {
                grid-column: -4/-2;
                width: unset;
                justify-content: flex-end;
                a {
                    margin-left: 28px;
                    max-width: min-content
                };
            }
        }
    }

    .skill_sheet {
        transform: rotateY(180deg);
        transition: unset;
        position: absolute;
        /* aspect-ratio: 0.5315542421562345; */
        width: calc(100% + 2px);
        max-width: calc(100% + 2px);
        top: 0;
        /* height: calc((((100vw - 224px)/9)*2 + 27px) / 0.5196551320650286);
        max-height: calc((((100vw - 224px)/9)*2 + 27px) / 0.5196551320650286); Impossible de tout le temps lui donner la taille de la carte, il faut donc passer par JavaScript...*/
        bottom: unset;
        z-index: -1;


        &__list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            max-height: unset;
            overflow: unset;
            button.skill_sheet__list__flip-button {
                border: none;
                border-top: #252525 1px solid;
                background-color: #644840;
                color: #F5F5F5;
                margin-bottom: 18px;
                padding: 5px 12px;
                font-size: 16px;
                max-width: fit-content;
                cursor: pointer;
            }
            .skill_sheet__list__flip-button:hover {
                background-color: #F5F5F5;
                color: #644840;
            }
            
            ul {
                padding-top: 28px;
                padding-bottom: 28px;
                height: calc(((((100vw - 244px) - 28px)/9)*2)/0.6571262507323195);
                overflow-y: scroll;
                overscroll-behavior-y: none;
                li {
                    flex-direction: column;
                    align-items: center;
                    :first-child {
                        text-align: center;
                    }
                    :last-child {
                        text-align: center
                    }
                    span {
                        max-width: 70%;
                    }
                }
                li:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #f5f5f5;
                    margin-top: 10px;
                }
            }
            ul::-webkit-scrollbar-thumb {
                background-color: #644840;
            }
            ul::-webkit-scrollbar-track {
                background-color: #252525;
            }
            ul::-webkit-scrollbar {
                width: 10px;
            }
        }
        /*
        width: calc(((100vw - 224px)/9)*2 + 28px);
        aspect-ratio: 0.5359184907;
        left: calc(((100vw - 224px)/9) + 28px);
        top: calc(50vh - ((((100vw - 224px)/9)*2 + 28px) / 0.5359184907)/2);
        padding: 0;
       /* display: none; */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #644840;
    }
    ::-webkit-scrollbar-track {
        background-color: #252525;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }

    .phone_card {
        position: relative;
        bottom: 0;
    }
    .behind {
        visibility: hidden;
    }
    main {
        display: flex;
        align-items: center;
        height: 100vh;
    }
    .height-adjuster {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 28px;
        row-gap: 0;
        align-content: start;
    }
    .phone_card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        perspective: 1000px;
        grid-column: 2/4;
        order: 1;
        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            border-left: solid 1px #252525;
            border-right: solid 1px #252525;
            padding-top: 11px;
            padding-bottom: 11px;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            h2 {
                margin: 0;
                font-size: 22px;
                text-align: center;
            }
        }
        &__main-content {
            display: block;
            width: 100%;
            height: min-content;
            &__image {
                width: 100%;
                height: min-content;
                aspect-ratio: 0.7687004510906862;
                min-width: unset;
                background-color: #FFF4E3;
                background-image: url("../assets/images/avatar_background.png");
                background-size: 175%;
                background-repeat: no-repeat;
                background-position: 8% 20%;
                border-left: #252525 1px solid;
                border-right: #252525 1px solid;
                picture {
                    display: unset;
                    height: unset;
                    img {
                        max-width: unset;
                        max-height: unset;
                        width: 100%;
                        height: unset;
                    }
                }
            }
        }
    }
    .minus {
        display: none;
    }
    @keyframes flip {
        100% {
            transform: rotateY(180deg);
        }
    }
    .rotate{
        animation: flip 1s linear;
    }
    .flipped-back {
        transform: rotateY(0);
        transition: unset;
    }
    .flipped {
        z-index: 2;
    }
    .back{
        z-index: -1;
    }
    .rotate-back {
        animation: flip 1s linear;
        .phone_card__header, .phone_card__main-content, .phone_card__header__right, .phone_card__footer__button-container {
            transform: rotateY(180deg);
        }
        
    }


    .phone_card__footer {
        perspective: 1000px;
        grid-column: 2/4;
        order: 3;
        background-color: #252525;
        width: 100%;
        height: min-content;
        border-right: solid 2px #252525;
        .phone_card__header__right {
            padding: 10px 28px 0 28px;
            &__level {
                display: flex;
                align-items: center;
                margin: 0;
                width: 100%;
                color: #F5F5F5;
                .progression-bar {
                    width: 100%;
                    height: 10px;
                }
            }
        }
        &__button-container {
            display: flex;
            padding: 10px 28px 18px 28px;
            justify-content: center;
            .skill_sheet__button {
                width: max-content;
                padding: 5px 7px;
                cursor: pointer;
            }
            .skill_sheet__button:hover {
                background-color: #F5F5F5;
                color: #644840;
            }
        }
    }

    .phone_card__main-content__text {
        height: unset;
        grid-column: 4/-2;
        order: 4;
        background-color: rgba(37, 37, 37, 0.9);
        color: #F5F5F5;
        margin-left: -28px;
        padding-left: 28px;
        padding-right: 0;
        p {
            width: 75%;
        }
    }
    .website_container {
        grid-column: 4/-2;
        order: 2;
        margin: 0;
        padding: 0;
        max-width: 100%;
        aspect-ratio: 1.740052742641531;
        overflow: hidden;
        &__slider {
            display: block;
            &__title {
                height: unset;
                width: 100%;
                aspect-ratio: 1.7400527426;
                &__presentation {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    p {
                        width: 70%;
                        text-align: center;
                        margin-top: 0;
                    }
                }
                h1 {
                    font-size: 96px;
                    margin-bottom: 0;
                }
            }
            &__website {
                aspect-ratio: 1.7400527426;
                margin: 0;
                transition: transform 0.2s;
                &__link_container {
                /*    aspect-ratio: 1.7400527426; */
                    height: 100%;
                }
                h2 {
                    font-size: 22px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    flex-grow: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                picture {
                    width: calc(100% - 2px);
                    height: calc(((((5*(100vw - 224px))/9) + 96px)/2.03606050297169) + 10px); /* à tester pour le +10 */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(280deg, #dddada, transparent);
                    border-left: 1px solid #252525;
                    border-right: 1px solid #252525;
                    border-bottom: 1px solid #252525;
                    img {
                        width: 80%;
                        box-shadow: #999999 3px 3px 12px;
                    }
                }
            }
            &__website:hover {
                transform: scale((0.95));
            }
        }
    }
    footer {
        flex-direction: row;
        height: 62px;
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        gap: 28px;
        :first-child {
            grid-column: 2/6;
            width: min-content;
        }
        :last-child {
            grid-column: -3/-2;
            display: flex;
            justify-content: flex-end;
        }
    }
    @media(max-height: 700px) {
        li {
            font-size: 16px;
            line-height: 22px;
        }
    }

}
@media(min-width: 1055px) {
    .phone_card__footer__button-container .skill_sheet__button {
        padding-left: 12px;
        padding-right: 12px
    }
}
@media(min-width: 1165px) {
    .skill_sheet {
        &__list {
            ul {
                li {
                    flex-direction: row;
                    align-items: unset;
                :first-child {    
                    text-align: unset;
                }
                :last-child {
                    text-align: end;
                }
                span {
                    max-width: 48%;
                }
                }
                li:after {
                    display: none;
                }
            }
        }
    }
}

@media(min-width: 1190px) {
    .phone_card {
        &__header {
            h2 {
                font-size: 26px;
            }
        }
    }
    .website_container {
        &__slider {
            &__website {
                h2 {
                    font-size: 26px;
                }
            }
        }
    }
}
@media(min-width: 1600px) {
    header {
        .header-container {
            height: 68px;
            grid-template-columns: repeat(11, 1fr);
            padding-left: 140px;
            padding-right: 140px;
            #français, #english {
                font-size: 20px;
            }

            .logo {
                width: 83px;
                height: 83px;
                border: 5px solid #F5F5F5;
                left: 140px;
                top: 21px;
                span {
                    font-size: 36px;
                }
            }
        }
    }
    .height-adjuster {
        grid-template-columns: repeat(11, 1fr);
        padding-left: 140px;
        padding-right: 140px;
    }
    p, li {
        font-size: 20px;
        line-height: 28px;
    }
    .website_container {
        grid-column: 5/-2;
        aspect-ratio: 1.369114563106796;
        h2 {
            font-size: 36px;
        }
        &__slider {
            &__title, &__website {
                aspect-ratio: 1.369114563106796;
            }
            &__website {
                picture {
                    height: calc(85.68386750356422% + 1px);
                }
            }

        }
    }
    .phone_card {
        grid-column: 2/5;
        &__header {
            h2 {
                font-size: 36px;
            }
        }
    }
    .phone_card__main-content__text {
        grid-column: 5/-2;
    }
    .phone_card__footer {
        grid-column: 2/5;
        h3 {
            font-size: 30px;
        }
        button {
            font-size: 20px;
        }
    }
    .skill_sheet {
        width: calc(((100vw - 560px)/11)*3 + 56px);
        &__list {
            button.skill_sheet__list__flip-button {
                font-size: 20px;
            }
        }
    }
    footer {
        width: calc(100% - 280px);
        padding-left: 140px;
        padding-right: 140px;
        grid-template-columns: repeat(11, 1fr);
        a {
            font-size: 20px;
        }
    }
}
/*
@media(min-width: 1300px) {
    .height-adjuster {
        grid-template-columns: repeat(11, 1fr);
    }
    .phone_card {
        grid-column: 2/4;
        &__header {
            h2{
                font-size: 23px;
            }
        }
    }
    .website_container {
        grid-column: 4/-2;
        aspect-ratio: unset;
        height: calc(((100vw - 280px)/11 + 28px) / 0.4087429511501631);
        &__slider {
            &__website{
                &__link_container {
                    
                }
            }
        }
    }
} */

/*
@media(min-width: 1000px) {


    p {
        font-size: 20px;
        line-height: 28px;
    }
    header {
        position: fixed;
        .header-container {
            display: grid;
            gap: 28px;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            padding: 0;
            .languages {
                grid-column: -3/-2;
                display: flex;
                justify-content: flex-end;
                a {
                    margin-left: 7px;
                }
            }
            .logo {
                left: calc((100% / 11) + 28px);
            }
        }
    }
    main {
        padding-top:50px;
        height: calc(100vh - 146px);
        display: flex;
        align-items: center;
        padding-bottom: 54px;
        padding-top: 54px;
        .page_content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 28px;
            row-gap:0;

        }
    }
    .website_container {
        overflow: hidden;
        margin-top: 0;
        margin: 0;
        display: unset;
        padding-left: 0;
        padding-right: 0;
        .slider {
            height: min-content;
            .website_container__title {
                justify-content: space-around;
                h1 {
                    font-size: 96px;
                }
                p {
                    margin-top:0;
                }
            }
            .website_container__website {
                margin: 0;
                display: flex;
                align-items: flex-start;
                h2 {
                    font-size: 36px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                }
                padding-bottom: 28px;
            }
        }


    }
    .wrappeur {
        grid-column: 5/-2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .description {
        background-color: rgba(37, 37, 37, 0.9);
        width: 100%;
        margin-left: -28px;
        padding-left: 28px;
        color: #F5F5F5;
        p {
            margin: 0;
        }
        .text-container {
            padding-top:14px;
            padding-right: 28px;
        }
    }
    .pc_card {
        grid-column: 2/5;
        height: min-content;
        &__header {
            text-align:center;
            padding-top: 11px;
            padding-bottom:11px;
            color: #F5F5F5;
            background-color: #252525;
            h2 {
                font-size: 36px;
                margin:0;
            }
        }
        &__image_container {
            background-color: #FFF4E3;
            background-image: url("../assets/images/avatar_background.png");
            background-size: 175%;
            background-repeat: no-repeat;
            background-position: 8% 20%;
            border-left: #252525 1px solid;
            border-right: #252525 1px solid;
            display: flex;
            justify-content: center;
            img {
                width: 90%;
                display: block;
            }
        }
        &__info_container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 18px 28px;
            background-color: #252525;
            &__progression-bar {
                width: 100%;
                background-color: #644840;
                height: 11px;
                display: flex;
                div{
                    background-color: #F5F5F5;
                    width: 50%;
                    height: 100%;
                    transform-origin: left;
                }
                
            }
            h2 {
                margin: 0;
                color: #F5F5F5;
            }
        }
    }
    footer {
        flex-direction: row;
        justify-content: space-between;
        height:62px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 28px;
        a {
            grid-column: 2/5;
            width: min-content;
        }
        .instagram {
            display: flex;
            justify-content: flex-end;
            grid-column: -3/-2;
        }
    }
}
@media(min-width: 1500px) {
    header {
        .header-container {
            padding-left: 140px;
            padding-right: 140px;
            width: unset;
            max-width: 100%;
            .logo {
                left: calc((100% / 11) + 130px);
            }
        }
    }
    main {
        .page_content {
            margin-left: 140px;
            margin-right: 140px;
        }
    }
    footer {
        max-width: 100%;
        a {
            padding-left: 140px;
        }
        .instagram {
            padding-right: 140px;
        }
    }
}
*/